import { FormikProps, useFormik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import deepEqual from 'deep-equal';
import { useDispatchApp } from 'redux/rootSelectors';
import { format } from 'date-fns';
import {
  getAllCounteragentsList,
  getOrdList,
} from 'domains/counteragents/model/actions';
import { fetchClients } from 'domains/clients/reducer';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  removeTopLevelsOfJson,
  TResult,
} from 'utils/jsonTransformativeFunctions/removeTopLevelsOfJson';
import { genFetchedData } from 'redux/fetchedData';
import {
  ContractFormikProps,
  ContractItem,
  UpdateContractAction,
} from '../types';
import { getContract, updateContract } from '../actions';
import { useContractData } from '../selectors';
import data from '../../../../jsonFiles/okved.json';
import { setContractData } from '../reducer';

interface UseCreateCounteragentReturn {
  formik: FormikProps<ContractFormikProps>;
  handleChange: (
    name: string,
    value: string | number | boolean | undefined,
  ) => void;
  isPrompt: boolean;
  externalId: string | undefined;
  isLoadingContractData: boolean;
  isLoadingUpdate: boolean;
  contractData: ContractItem | null;
  error: ReturnType<typeof useContractData>['error'];
  options: TResult[];
  getContractData: () => void;
}

export const useUpdateContract = (): UseCreateCounteragentReturn => {
  const { t } = useTranslation();

  const [disablePrompt, setDisablePrompt] = useState<boolean>(false);

  const dispatch = useDispatchApp();

  const { id: uuid }: { id: string } = useParams();

  const {
    data: contractData,
    isLoading: isLoadingContractData,
    error,
  } = useContractData();

  const [isLoadingUpdate, setIsLoading] = useState<boolean>(false);

  const history = useHistory();

  const options = useMemo(() => removeTopLevelsOfJson(data), [data]);

  const getTitlesFromOkved = (): TResult[] => {
    const result: TResult[] = [];
    if (contractData) {
      contractData.creativeOkveds.forEach((okved) => {
        options.forEach((option) => {
          if (option.label === okved) {
            result.push(option);
          }
        });
      });
    }
    return result;
  };

  const goBack = () => {
    setDisablePrompt(true);
    history.push('/main/contracts');
  };

  const getContractData = () => {
    dispatch(getContract({ uuid }));
  };

  useEffect(() => {
    dispatch(getAllCounteragentsList());
    dispatch(fetchClients({}));
    dispatch(getOrdList());
    getContractData();
  }, []);

  const formik = useFormik<ContractFormikProps>({
    initialValues: {
      client_xxhash_list:
        contractData?.partners.map(({ xxhash, title }) => ({
          label: title,
          value: xxhash,
        })) || null,
      ord: contractData?.ord || null, // Основная ОРД
      externalId: contractData?.externalId || '', // Идентификатор договора в ORD
      customer: contractData?.customer.uuid || '', // Заказчик
      executor: contractData?.executor.uuid || '', // Исполнитель
      number: contractData?.number || '', // Номер договора
      date: contractData?.date ? new Date(+contractData.date * 1000) : null, // Дата договора
      type: contractData?.type || 'Intermediary', // Тип договора
      actionType: contractData?.actionType || 'Other', // Действия, осуществляемые посредником-представителем
      subjectType: contractData?.subjectType || 'Other', // Сведения о предмете договора
      amount: contractData?.amount || 0, // Сумма договора (руб)
      executorIsObligedForRegistration:
        !!contractData?.executorIsObligedForRegistration, // Обязанность регистрировать и репортировать креативы исполнителем
      isInvoiceContract: !!contractData?.isInvoiceContract, // Договор отчета. По умолчанию - false
      category: 'Initial', // Категория договора.
      agentActingForPublisher:
        typeof contractData?.agentActingForPublisher === 'boolean'
          ? contractData?.agentActingForPublisher
          : null, // Направление денежных средств.
      creativeOkveds: getTitlesFromOkved() || null, // ОКВЭД
      campaign_ord: contractData?.campaign_ord,
      /* finalContract: '', */ // Доходный договор.
      /* parent: '',  */ // Родительский договор.
      /* useNetworkStat: false, */
    },
    validationSchema: Yup.object({
      client_xxhash_list: Yup.array()
        .nullable()
        .min(1, t(`ord.errors.required_field`))
        .required(t(`ord.errors.required_field`)),
      ord: Yup.number().nullable().required(t(`ord.errors.required_field`)),
      /* externalId: Yup.string().nullable(), */
      customer: Yup.string()
        .nullable()
        .required(t(`ord.errors.required_field`)),
      executor: Yup.string()
        .nullable()
        .required(t(`ord.errors.required_field`)),
      number: Yup.string().required(t(`ord.errors.required_field`)),
      date: Yup.date()
        .typeError(t(`ord.errors.incorrect_value`))
        .nullable()
        .required(t('campaigns_page.campaign_settings.date_hint.h2')),
      type: Yup.string().nullable().required(t(`ord.errors.required_field`)),
      actionType: Yup.string()
        .nullable()
        .required(t(`ord.errors.required_field`)),
      subjectType: Yup.string()
        .nullable()
        .required(t(`ord.errors.required_field`)),
      amount: Yup.number()
        .min(0, t(`ord.errors.min_value_0`))
        .max(1e16, t(`ord.errors.max_value_exceeded`))
        .typeError(t(`ord.errors.incorrect_value`))
        .required(t(`ord.errors.required_field`)),
      executorIsObligedForRegistration: Yup.boolean().nullable(),
      isInvoiceContract: Yup.boolean().nullable(),
      creativeOkveds: Yup.array()
        .nullable()
        .notRequired()
        .test('creativeOkveds', t(`form.common.fill_the_field`), (value) => {
          if (
            contractData &&
            contractData.creativeOkveds.length > 0 &&
            value?.length === 0
          ) {
            return false;
          }
          return true;
        }),
      agentActingForPublisher: Yup.boolean().required(
        t(`ord.errors.required_field`),
      ),
      /*       parent: Yup.object()
        .shape({ uuid: Yup.string().nullable() })
        .when('type', ([type], schema) =>
          schema.test({
            test: (value) => !(!value?.uuid && type === 'Additional'),
            message: t(`ord.errors.required_field`),
          }),
        )
        .nullable(),
      finalContract: Yup.object()
        .when(['ord', 'category'], ([ord, category], schema) =>
          schema.test({
            test: () => !(ord === 3 && category === 'Initial'),
            message: t(`ord.errors.required_field`),
          }),
        )
        .nullable(),
      useNetworkStat: Yup.number(), */
    }),
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: (values) => {
      const { client_xxhash_list, ...objectCuster } = values;
      const { creativeOkveds } = objectCuster;
      const resultOkveds: string[] = [];

      if (creativeOkveds) {
        Object.values(creativeOkveds).forEach((okved) => {
          Object.keys(okved).forEach((ok) => {
            if (ok === 'label') {
              resultOkveds.push(okved[ok]);
            }
          });
        });
      }

      const params: UpdateContractAction = {
        uuid,
        client_xxhash_list: client_xxhash_list?.map((item) => item.value) || [],
        objectCuster: {
          ...objectCuster,
          creativeOkveds: resultOkveds || [],
          date: values.date ? format(values.date, 'yyyy-MM-dd') : undefined,
        },
        callbacks: {
          goBack,
          setIsLoading,
        },
        contractData,
      };

      dispatch(updateContract(params));
    },
  });

  const isPrompt =
    !disablePrompt && !deepEqual(formik.values, formik.initialValues);

  const handleChange = useCallback(
    (name: string, value: string | number | boolean | undefined) => {
      let newRD = genFetchedData<ContractItem>(contractData);
      newRD = newRD.set('error', {
        isError: false,
        message: '',
        code: 0,
      });
      dispatch(setContractData(newRD));
      formik.setFieldValue(name, value).then(() => {
        formik.validateField(name);
      });

      formik.setFieldValue(name, value).then(() => {
        formik.validateField(name);
        if (name === 'type') {
          formik.validateField('customer');
          formik.validateField('executor');
          /*           formik.validateField('parent'); */
        }
        if (
          name === 'type' &&
          value === 'Intermediary' &&
          formik.values.actionType === 'None'
        ) {
          formik.setFieldValue('actionType', '');
        }
        if (name === 'ord') {
          formik.setFieldValue('customer', null);
          formik.setFieldValue('executor', null);
          formik.validateField('finalContract');
        }
        if (name === 'category') {
          formik.validateField('finalContract');
        }
        if (name === 'creativeOkveds') {
          formik.validateField('creativeOkveds');
        }
      });
    },
    [formik],
  );

  useEffect(
    () => () => {
      let newRD = genFetchedData<ContractItem>(contractData);
      newRD = newRD.set('error', {
        isError: false,
        message: '',
        code: 0,
      });
      dispatch(setContractData(newRD));
    },
    [],
  );

  return {
    formik,
    externalId: contractData?.externalId,
    handleChange,
    isLoadingContractData,
    isLoadingUpdate,
    contractData,
    isPrompt,
    error,
    options,
    getContractData,
  };
};
