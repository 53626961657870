import {
  RouteComponentProps,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatchApp } from 'redux/rootSelectors';
import { fetchPersonalData } from 'domains/campaign/model/actions';
import { FormikProps, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';
import { checkResize } from 'domains/uploads/helpers';
import { useOffersList } from 'domains/offers/model/selectors';
import {
  TCreativeHTML,
  TCreativeMultiFormat,
  TCreativePush,
  TCreativeRouter,
  TCreativeTeaser,
  TCreativeVideoFormik,
  TCreativeClickUnder,
  TCreativeVideoVast,
  TCreativeYandex,
  TFormikCreativeAdd,
  TCreativeCompanion,
} from 'domains/creative/types';
import { SelectField, TID } from '../../../../../types/general';
import { validate } from '../validate';
import { objectOptimizationWithFormik } from '../../../../../utils/objectOptimizationWithFormik/objectOptimizationWithFormik';
import {
  addCreativeRequest,
  featchAllCreativeTypesAction,
  setResponseResultDefault,
} from '../../../reduser';
import { useCreativeAlgorithmsInfo } from '../../hooks';
import { selectDefault } from '../../../../../utils/selectDefault';
import { useCampaignInfo } from '../../../../campaign/model/selectors';
import { useCreativeResponseInfo } from '../../../model/selectors';
import { useCreativeSize } from '../../../model/hooks/useCreativeSize';
import { useDataAdditional } from '../../../model/hooks/useDataAdditional';
import { useValidateVendorLInk } from '../../../model/hooks/useValidateVendorLInk';
import { toastSuccess } from '../../../../../utils/ToastUtils/toastUtils';
import { useValidateSchemeCreatives } from '../../validate/validate';

type TFUseCreativeAdd = () => {
  isLoading: ReturnType<typeof useCampaignInfo>['isLoading'];
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
  id: string;
  formik: FormikProps<TFormikCreativeAdd>;
  goBack: (v?: string) => void;
  violence: ReturnType<typeof useDataAdditional>['violence'];
  selectDefault: typeof selectDefault;
  sex: ReturnType<typeof useDataAdditional>['sex'];
  algorithmsSelect: ReturnType<
    typeof useCreativeAlgorithmsInfo
  >['algorithmsSelect'];
  sizeOption: ReturnType<typeof useCreativeSize>['sizeOption'];
  checkSizes: ReturnType<typeof useCreativeSize>['checkSizes'];
  defaultSizeOption: (v?: string) => SelectField[];
  error: ReturnType<typeof useCreativeResponseInfo>['error'];
  isLoadingResponse: ReturnType<typeof useCreativeResponseInfo>['isLoading'];
  handlerCopyHtml: () => void;
  tags: string[];
  updateTags: (v: string[]) => void;
  lState: TCreativeRouter;
  helper: ReturnType<typeof useValidateVendorLInk>['helper'];
};

export const useCreativeAdd: TFUseCreativeAdd = () => {
  const { t } = useTranslation();
  const { creativeValidateScheme } = useValidateSchemeCreatives();

  useEffect(() => {
    dispatch(featchAllCreativeTypesAction());
  }, []);

  const { error, isLoading: isLoadingResponse } = useCreativeResponseInfo();
  const dispatch = useDispatchApp();

  const { algorithmsSelect } = useCreativeAlgorithmsInfo();

  const location = useLocation();
  const lState = location.state as TCreativeRouter;
  const history = useHistory();
  const {
    params: { id },
  } = useRouteMatch<TID>();
  const goBack: ReturnType<TFUseCreativeAdd>['goBack'] = (xxhash) => {
    if (xxhash) {
      history.push(
        id
          ? `/main/campaign/${id}/creatives${
              !!xxhash && `?highlights=${xxhash}`
            }`
          : '/main/campaigns',
      );
    } else {
      history.push(id ? `/main/campaign/${id}/creatives` : '/main/campaigns');
    }
  };
  const { data: campaign, isLoading } = useCampaignInfo();

  const [tags, setTags] = useState<string[]>([]);

  const updateTags: ReturnType<TFUseCreativeAdd>['updateTags'] = (v) => {
    setTags(() => v);
  };

  const offerId = campaign?.offer_id;

  const { data: offersList } = useOffersList();

  const currentOffer = offersList?.find(
    (offer) => offer.id === offerId,
  );

  const formik = useFormik<TFormikCreativeAdd>({
    validate,
    validationSchema: creativeValidateScheme,
    initialValues: {
      campaign_xxhash: '',
      title: t('creative.create_creative.newTitle'),
      erid: '',
      type: lState ? lState.type : 'HTML',
      status: 'STOPPED',
      advertiser_name: '',
      categories: {
        erotic_rank: 0,
        iab: '',
        is_animated: false,
        violence_rank: 0,
      },
      adomain: currentOffer?.adomain,
      language: '',
      algorithm_cpc: {
        id: 1,
        min: 0,
        max: 0,
      },
      link: currentOffer?.link,
      iurl: '',
      extension_link: '',
      pixel: currentOffer?.pixel,
      pixel_additional: '',
      js_code_audit: '',
      click_audit_pixel: '',
      size: '',
      width: '',
      height: '',
      data: {
        show_html: '',
        use_vpaid: false,
        images: {
          icon: '',
          image_1: '',
          image_2: '',
        },
        description: '',
        img_yandex_html: '',
        yandex_brand: '',
        yandex_category: '',
        zip_yandex_html: '',
        alt_text: '',
        imgFormats: [],
        imgMask: '',
        iconFormats: [],
        iconMask: '',
        text: '',
        title: '',
        html_code: '',
        video_url: '',
        audit: {
          click: '',
          close: '',
          collapse: '',
          exitfs: '',
          expand: '',
          fullscreen: '',
          mute: '',
          pause: '',
          resume: '',
          rewind: '',
          show: '',
          show2: '',
          skip: '',
          sprogresshow: '',
          unmute: '',
          view_0: '',
          view_25: '',
          view_50: '',
          view_75: '',
          view_100: '',
        },
        duration: 0,
        skip_duration: 0,
        video_bitrate: 0,
        file_size: 0,
        video_poster_url: '',
        vast_url: '',
        button: '',
        legal_information: '',
      },
      additional_settings_switch: 'SHOW',
      kktu_codes: '',
    },
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      const param = objectOptimizationWithFormik<
        TCreativeHTML &
          TCreativeTeaser &
          TCreativePush &
          TCreativeYandex &
          TCreativeVideoFormik &
          TCreativeClickUnder &
          TCreativeVideoVast &
          TCreativeMultiFormat & 
          TCreativeCompanion
      >(
        {
          iconFormats: [],
          imgFormats: [],
          show_html: '',
          img_yandex_html: '',
          yandex_brand: '',
          yandex_category: '',
          zip_yandex_html: '',
          alt_text: '',
          imgMask: '',
          iconMask: '',
          use_vpaid: false,
          text: '',
          title: '',
          html_code: '',
          description: '',
          audit: {
            click: '',
            close: '',
            collapse: '',
            exitfs: '',
            expand: '',
            fullscreen: '',
            mute: '',
            pause: '',
            resume: '',
            rewind: '',
            show: '',
            show2: '',
            skip: '',
            sprogresshow: '',
            unmute: '',
            view_0: '',
            view_25: '',
            view_50: '',
            view_75: '',
            view_100: '',
          },
          video_url: '',
          duration: 0,
          skip_duration: 0,
          video_bitrate: 0,
          file_size: 0,
          video_poster_url: '',
          vast_url: '',
          images: {
            icon: '',
            image_1: '',
            image_2: '',
          },
          button: '',
          legal_information: '',
        },
        values.data as TCreativeHTML &
          TCreativeTeaser &
          TCreativePush &
          TCreativeYandex &
          TCreativeVideoFormik &
          TCreativeClickUnder &
          TCreativeVideoVast &
          TCreativeMultiFormat & 
          TCreativeCompanion,
      );
      if (
        (values.type === 'VIDEO_INLINE' ||
          values.type === 'HTML' ||
          values.type === 'VIDEO_VAST' ||
          values.type === 'CLICKUNDER' ||
          /* COMPANION под вопросом */
          values.type === 'COMPANION') &&
          values.height &&
          values.width &&
        (values.size === '' ||
          values.size === '1x1' ||
          values.size === undefined)
      ) {
        // eslint-disable-next-line no-param-reassign
        values.size = `${values.width}x${values.height}`;
      }

      if (values.type === 'MULTI_FORMAT') {
        dispatch(
          addCreativeRequest({
            tags,
            data: {
              title: values.title,
              campaign_xxhash: id,
              type: values.type,
              data: {
                title: values.data.title,
                button: values.data.button,
                legal_information: values.data.legal_information,
                images: values.data.images,
                text: values.data.text,
              },
              link: values.link,
              iurl: values.iurl,
            },
            callback: goBack,
          }),
        );
        return;
      }
      if (param) {
        dispatch(
          addCreativeRequest({
            tags,
            data: {
              ...values,
              campaign_xxhash: id,
              categories: {
                erotic_rank: +values.categories.erotic_rank,
                violence_rank: +values.categories.violence_rank,
                is_animated: values.categories.is_animated,
                iab: values.categories.iab,
              },
              data:
                values.type === 'PUSH'
                  ? { ...param, imgFormats: checkResize(values.type) }
                  : param,
              algorithm_cpc: {
                ...values.algorithm_cpc,
                max:
                  +values.algorithm_cpc.id !== 1
                    ? +values.algorithm_cpc.max || 0
                    : 0,
                min:
                  +values.algorithm_cpc.id !== 1
                    ? +values.algorithm_cpc.min || 0
                    : 0,
              },
            },
            callback: goBack,
          }),
        );
        return;
      }
      dispatch(
        addCreativeRequest({
          tags,
          data: {
            ...values,
            campaign_xxhash: id,
            data: {},
            categories: {
              erotic_rank: +values.categories.erotic_rank,
              violence_rank: +values.categories.violence_rank,
              is_animated: values.categories.is_animated,
              iab: values.categories.iab,
            },
            algorithm_cpc: {
              ...values.algorithm_cpc,
              max:
                +values.algorithm_cpc.id !== 1
                  ? +values.algorithm_cpc.max || 0
                  : 0,
              min:
                +values.algorithm_cpc.id !== 1
                  ? +values.algorithm_cpc.min || 0
                  : 0,
            },
          },
          callback: goBack,
        }),
      );
    },
  });

  useEffect(() => {
    if (!campaign) {
      dispatch(fetchPersonalData(id));
    }
    if (campaign) {
      formik.setValues({
        campaign_xxhash: campaign.xxhash,
        type: lState ? lState.type : 'HTML',
        title: t('creative.create_creative.newTitle'),
        advertiser_name: campaign.advertiser_name,
        height: '',
        width: '',
        js_code_audit: '',
        click_audit_pixel: '',
        iurl: '',
        categories: {
          erotic_rank: 0,
          iab: '',
          is_animated: false,
          violence_rank: 0,
        },
        algorithm_cpc: {
          id: 1,
          min: 0,
          max: 0,
        },
        data: {
          img_yandex_html: '',
          show_html: '',
          yandex_brand: '',
          yandex_category: '',
          zip_yandex_html: '',
          alt_text: '',
          imgFormats: [],
          iconFormats: [],
          use_vpaid: false,
          imgMask: '',
          iconMask: '',
          text: '',
          title: '',
          html_code: '',
          video_bitrate: 0,
          skip_duration: 0,
          duration: 0,
          description: '',
          audit: {
            view_100: '',
            view_75: '',
            view_50: '',
            view_25: '',
            view_0: '',
            unmute: '',
            sprogresshow: '',
            skip: '',
            show: '',
            show2: '',
            rewind: '',
            resume: '',
            pause: '',
            mute: '',
            fullscreen: '',
            expand: '',
            exitfs: '',
            collapse: '',
            close: '',
            click: '',
          },
          video_poster_url: '',
          file_size: 0,
          video_url: '',
          vast_url: '',
          button: 'Перейти',
          images: {
            icon: '',
            image_1: '',
            image_2: '',
          },
          legal_information: '',
        },
        additional_settings_switch: 'ALL',
        kktu_codes: campaign.kktu_codes || ''
      });
    }
  }, [campaign]);

  useEffect(() => {
    formik.validateForm();
  }, []);

  const { sizeOption, checkSizes } = useCreativeSize();
  const { violence, sex } = useDataAdditional();

  const sizeOptionVideoVast = [
    { label: '576x320', value: '576x320' },
    { label: '1920x1080', value: '1920x1080' },
    { label: t('creative.create_creative.Set_sizes'), value: '1x1' },
  ];

  /** Задает значение размера по умолчанию */
  const defaultSizeOption = (size: string | undefined) => {
    if (size === 'undefined' || size === '' || size === 'null') {
      return [];
    }
    const data: SelectField[] =
      lState && (lState.type === 'VIDEO_VAST' || lState.type === 'CLICKUNDER')
        ? sizeOptionVideoVast
        : sizeOption;
    const defaultValue = data.filter((v) => v.value === size);
    if (defaultValue.length) {
      return defaultValue;
    }

    return [
      { label: t('creative.create_creative.Set_sizes'), value: '1x1' },
    ];
  };

  /** Функция копирует HTML code */
  const handlerCopyHtml = () => {
    if (formik.values.data.html_code) {
      copy(formik.values.data.html_code);
      toastSuccess(t('alerts.a2'));
    }
  };

  useEffect(
    () =>
      function () {
        dispatch(setResponseResultDefault());
      },
    [],
  );

  useEffect(() => {
    formik.validateForm();
  }, [formik.values.width, formik.values.height])

  const { helper } = useValidateVendorLInk({
    formik: {
      link: formik.values.link ?? '',
      pixel: formik.values.pixel ?? '',
      show_html: formik.values.data?.show_html ?? '',
      pixel_additional: formik.values.pixel_additional ?? '',
      show: formik.values.data?.audit?.show,
      show2: formik.values.data?.audit?.show2,
      extension_link: formik.values?.extension_link,
      js_code_audit: formik.values?.js_code_audit ?? '',
    },
    adomain: formik.values.adomain,
  });

  return {
    isLoading,
    location,
    history,
    id,
    formik,
    goBack,
    violence,
    selectDefault,
    sex,
    algorithmsSelect,
    sizeOption:
      lState && (lState.type === 'VIDEO_VAST' || lState.type === 'CLICKUNDER')
        ? sizeOptionVideoVast
        : sizeOption,
    checkSizes,
    defaultSizeOption,
    error,
    isLoadingResponse,
    handlerCopyHtml,
    tags,
    updateTags,
    lState: lState || { type: 'HTML' },
    helper,
  };
};
