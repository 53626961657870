import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'components/UI/Card';
import { FormikProps } from 'formik';
import Help from 'components/Help';
import { useUserInfo, useUserTheme } from 'domains/user/model/selectors';
import InputAutoSave from 'components/UI/InputAutoSave';
import { TFormikCreativeAdd } from 'domains/creative/types';
import { someGroups } from 'utils/statics/everyGroups';
import { useClientInfo } from 'domains/client/model/selectors';
import css from '../styles.module.scss';
import TagAutoSave from '../../../../../../components/UI/TagAutoSave';

type Props<T> = {
  formik: FormikProps<T>;
  tags: string[];
  setTags: (v: string[]) => void;
};

const Main: React.FC<Props<TFormikCreativeAdd>> = ({
  formik: { values, errors, touched, setFieldValue, handleBlur },
  tags,
  setTags,
}) => {
  const { t } = useTranslation();
  const { getValueFromSettingForKey } = useUserTheme();
  const { data: partner } = useClientInfo();
  const { data: user } = useUserInfo();

  const showKktuField = (): boolean => {
    if (
      values.type === 'HTML' ||
      values.type === 'TEASER' ||
      values.type === 'VIDEO_VAST' ||
      values.type === 'VIDEO_INLINE' &&
      someGroups(user, 'owner', 'self_user') &&
      !!partner?.ord_registration_creatives
    )
      return true;

    return false;
  };

  return (
    <Card noHover className={css.card}>
      <h3 className={css.title}>
        {values.type === 'CLICKUNDER'
          ? t('creative.type.clickunder')
          : t('creative.creative_text')}
        {getValueFromSettingForKey('HideHelp') && (
          <Help
            width={150}
            title={
              values.type === 'CLICKUNDER'
                ? t('creative.type.clickunder')
                : t('creative.creative_text')
            }
            helpattributes={{
              link: 'https://terratraf.readme.io/docs/td-creatives-clickander',
              sizeIcon: 18,
            }}
          />
        )}
      </h3>
      <div className={css.form}>
        {values.type !== 'MULTI_FORMAT' && (
          <InputAutoSave
            label={t('creative.create_creative.Advertiser_name')}
            value={String(values.advertiser_name)}
            name="advertiser_name"
            placeholder={t('creative.create_creative.Advertiser_name')}
            maxLength={80}
            setFieldValue={setFieldValue}
            isError={!!touched.advertiser_name && !!errors.advertiser_name}
            errorText={errors.advertiser_name}
            onBlur={handleBlur}
          />
        )}

        <InputAutoSave
          label={t('creative.create_creative.Name')}
          value={String(values.title)}
          name="title"
          placeholder={t('creative.create_creative.Name')}
          maxLength={120}
          setFieldValue={setFieldValue}
          isError={!!touched.title && !!errors.title}
          errorText={errors.title}
          onBlur={handleBlur}
        />

        {showKktuField() && (
          <InputAutoSave
            label="КТУ"
            value={values.kktu_codes}
            name="kktu_codes"
            placeholder='КТУ'
            setFieldValue={setFieldValue}
            isError={!!errors.kktu_codes}
            errorText={errors.kktu_codes}
            onBlur={handleBlur}
          />
        )}

        {values.type !== 'MULTI_FORMAT' && (
          <TagAutoSave
            tagsData={tags}
            handlerData={setTags}
            theme="horizontal"
          />
        )}
      </div>
    </Card>
  );
};

export default Main;
